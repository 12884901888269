// extracted by mini-css-extract-plugin
export var sectionheader = "index-module--sectionheader--DRYu7";
export var user = "index-module--user--HhxCM";
export var imgdiv = "index-module--imgdiv--3kqft";
export var question = "index-module--question--2h4os";
export var titleimg = "index-module--titleimg--2lleJ";
export var faqblock = "index-module--faqblock--3loiY";
export var answer = "index-module--answer--3o-SZ";
export var theme = "index-module--theme--3dyFN";
export var faqblockline = "index-module--faqblockline--184xn";
export var faqleft = "index-module--faqleft--296HM";
export var faqright = "index-module--faqright--3MbMn";
export var detailsdiv = "index-module--detailsdiv--2kJlB";